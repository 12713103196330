import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { graphql } from "gatsby";
import HeroSectionComponent from "../components/HeroSectionComponent";
import ContentWithSideBar from "../components/ContentWithSideBar";
import Sidebar from "../components/Sidebar";
import BlockLatestProductList from "../components/BlockLatestProductList";
import Contentwithoutsb from "../components/Contentwithoutsb";
import ProductRange from "../components/ProductRange";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/SEO";

const ContactUsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  @media (min-width: 1024px) {
    padding-right: 2.4rem;
    margin-bottom: 0px;
  }

  address {
    font-style: normal;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  a {
    color: var(--black);
    transition: color 0.4s ease;

    @media (min-width: 768px) {
      &:hover {
        color: var(--red-light);
      }
    }
  }

  h4 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    font-family: var(--font-oswald-regular);
    text-transform: uppercase;
  }

  a,
  p {
    font-size: 2.1rem;
    margin-top: 0px;
    display: inline-block;
    margin-bottom: 1.8rem;
  }
`;

const ContactUsWrapperImageAddress = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap-reverse;

  @media (min-width: 768px) {
    flex-wrap: initial;
  }
`;

const ContactUsGoogleImageWrapper = styled.div`
  width: 100%;
`;

const ContactAddressGrid = styled.ul`
  margin: 0px 0px 2rem;
  padding: 0px;
  list-style: none;
`;

const FormWrapper = styled.div`
  margin-bottom: 5rem;
  position: relative;

  @media (min-width: 768px) {
    padding-left: 5rem;
  }

  z-index: 0;

  input,
  textarea {
    outline: none;
    &:focus {
      border-color: var(--black);
    }
  }

  button {
    align-items: center;
    display: flex;
    background-color: var(--black);
    color: var(--white);
    cursor: pointer;
    justify-content: center;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    font-family: var(--font-oswald-regular);
    font-size: 1.6rem;
    text-transform: uppercase;
    height: 4rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2rem;
    border: 0px;
    transition: background-color 0.25s ease;

    @media (min-width: 768px) {
      margin: 0px;

      &:hover {
        background-color: var(--red-dark);
      }
    }
  }
`;

const InputWrapper = styled.p`
  position: relative;
  display: block;
  width: 100%;

  label {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-family: var(--font-oswald-bold);
    text-transform: uppercase;
  }

  input {
    border: 2px solid var(--grey-light);
    border-radius: var(--border-small);
    padding: 1rem 1.2rem;
    font-family: var(--font-quattrocento-regular);
    font-size: 1.4rem;
    width: 100%;
  }
`;

const TextareaWrapper = styled.p`
  position: relative;
  display: block;
  width: 100%;

  label {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-family: var(--font-oswald-bold);
    text-transform: uppercase;
  }

  textarea {
    border: 2px solid var(--grey-light);
    border-radius: var(--border-small);
    padding: 1rem 1.2rem;
    font-family: var(--font-quattrocento-regular);
    font-size: 1.4rem;
    width: 100%;
  }
`;

const SucessMessageWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  border-radius: var(--border-regular);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  bottom: 0;
  width: 90%;
  height: 96%;
  background: var(--white);
  z-index: 1;
  margin: auto;
  flex-direction: column;
  padding: 1rem 5rem;
  text-align: center;

  @media (min-width: 768px) {
    left: 5rem;
  }
`;

export default function AboutUs({ data }) {
  const [success, setSuccess] = useState(false);

  const {
    hero_text,
    hero_title,
    hero_background_image,
    hero_image,
    address_address,
    address_email_address,
    address_email_title,
    address_fax_number,
    address_fax_title,
    address_tel_number,
    address_tel_title,
    address_title,
    address_google_image,
  } = data.contactUs;

  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      setSuccess(true);
    }
  }, []);

  function closeMessage() {
    window.location.assign("/contact-us");
  }

  return (
    <>
      <Seo
        title={hero_title}
        description={hero_text}
        image={hero_image?.asset?.url}
        location={useLocation()}
      />
      <HeroSectionComponent
        title={hero_title}
        text={hero_text}
        heroImage={false}
        heroBackgroundImage={hero_background_image.asset.gatsbyImageData}
      />

      <ContentWithSideBar>
        <ContactUsWrapper>
          <ContactUsWrapperImageAddress>
            <address>
              <ContactAddressGrid>
                <li>
                  <h4>{address_title}</h4>
                  <p>{address_address}</p>
                </li>
                <li>
                  <h4>{address_tel_title}</h4>
                  <a href={`tel:+${address_tel_number}`}>
                    {address_tel_number}
                  </a>
                </li>
                <li>
                  <h4>{address_email_title}</h4>
                  <a href={`mailto:${address_email_address}`}>
                    {address_email_address}
                  </a>
                </li>
                <li>
                  <h4>{address_fax_title}</h4>
                  <p>{address_fax_number}</p>
                </li>
              </ContactAddressGrid>
            </address>
            <FormWrapper>
              {success && (
                <SucessMessageWrapper>
                  <p>
                    Thank you for your request, a team member will get back to
                    you soon.
                  </p>
                  <button className='black-button' onClick={closeMessage}>
                    close
                  </button>
                </SucessMessageWrapper>
              )}
              <form
                name='contact'
                method='POST'
                data-netlify='true'
                action='/contact-us/?success=true'
                netlify-honeypot='bot-field'
              >
                <input type='hidden' name='form-name' value='contact' />
                <InputWrapper>
                  <label htmlFor='name'>Full name (required)</label>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    placeholder='John Doe'
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <label htmlFor='email'>Email (required)</label>
                  <input
                    type='text'
                    id='email'
                    name='email'
                    placeholder='john@email.com'
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <label htmlFor='phone'>Phone</label>
                  <input
                    type='tel'
                    id='phone'
                    placeholder='0394950833'
                    name='phone'
                  />
                </InputWrapper>
                <TextareaWrapper>
                  <label htmlFor='message'>Message</label>
                  <textarea
                    id='message'
                    name='message'
                    cols='30'
                    rows='5'
                  ></textarea>
                </TextareaWrapper>
                <button type='submit'>Send</button>
              </form>
            </FormWrapper>
          </ContactUsWrapperImageAddress>
          <ContactUsGoogleImageWrapper>
            <GatsbyImage
              image={address_google_image.asset.gatsbyImageData}
              alt=''
            />
          </ContactUsGoogleImageWrapper>
        </ContactUsWrapper>
        <Sidebar withPartnering={false} />
      </ContentWithSideBar>
      <BlockLatestProductList
        title='latest products'
        link='/our-products'
        linkText='view all products'
        borderColor='var(--grey-light)'
        smallVersion={false}
      />
      <Contentwithoutsb>
        <ProductRange />
      </Contentwithoutsb>
    </>
  );
}

export const query = graphql`
  query ContactUsQuery {
    contactUs: sanityContactPage {
      hero_title
      hero_text
      hero_image {
        asset {
          url
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

      hero_background_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

      address_address
      address_email_address
      address_email_title
      address_fax_number
      address_fax_title
      address_tel_number
      address_tel_title
      address_title
      address_google_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      address_hero_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
